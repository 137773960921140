.RV_MAContainer{
    height: 100%;
    width: 100%;
    border: 1px solid #cacaca;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top:20px;
    padding-left:20px;
}