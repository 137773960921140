.RV_LCContainer{
    height: 100%;
    width: 244px;
    border: 1px solid #cacaca;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-top:20px;
}