.RV_UBContainer{
    width: 100%;
    height: 77px;
    border: 0px solid #cacaca;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    padding-left:20px;
    background-color: #76B0E4;
}

.RV_UBLabel{
    color: #f9fbfd;
    font-family: DMSans, serif;
    font-size: 18px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 60px;

}

.RV_UBLabel:hover {
    cursor: pointer;
    color: #092c4c;
}

.RV_UBRightContainer{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding-right:90px;
}